<template>
  <div>
    <svg
      display="none"
      width="0"
      height="0"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <symbol id="icon-news" viewBox="0 0 1024 1024">
          <title>news</title>
          <path
            class="path1"
            d="M881.042 426.146c-95.665 5.016-215.659 36.212-291.115 65.209v-48.783c80.813-27.34 190.644-57.873 291.115-63.151v46.725zM881.042 465.247c-100.471 5.269-210.302 35.802-291.115 63.151v48.783c75.456-28.997 195.452-60.191 291.115-65.209v-46.725zM881.042 261.216c-100.471 5.269-210.302 35.802-291.115 63.151v80.354c75.456-28.997 195.452-60.191 291.115-65.209v-78.296zM1012.344 104.208v702.742c-178.849 8.578-341.687 39.817-500.344 112.84-158.648-73.014-321.495-104.253-500.344-112.84v-702.742c176.113 8.666 372.624 44.685 500.344 114.388 127.72-69.712 324.231-105.722 500.344-114.388zM489.937 292.745c-104.441-62.488-276.411-93.747-405.411-109.243v555.143c147.706 12.038 289.081 43.034 405.411 87.919v-533.81zM939.474 183.493c-129.009 15.496-300.97 46.755-405.411 109.243v533.81c116.33-44.887 257.714-75.881 405.411-87.919v-555.143zM881.042 551.872c-100.471 5.269-210.302 35.802-291.115 63.151v48.783c75.456-28.997 195.452-60.191 291.115-65.209v-46.725zM881.042 640.117c-100.471 5.269-210.302 35.802-291.115 63.151v48.783c75.456-28.997 195.452-60.191 291.115-65.209v-46.725zM434.073 442.572c-80.813-27.34-190.644-57.873-291.115-63.151v46.725c95.665 5.016 215.659 36.212 291.115 65.209v-48.783zM434.073 703.268c-80.813-27.34-190.644-57.873-291.115-63.151v46.725c95.665 5.016 215.659 36.212 291.115 65.209v-48.783zM434.073 324.367c-80.813-27.34-190.644-57.873-291.115-63.151v78.296c95.665 5.016 215.659 36.212 291.115 65.209v-80.354zM434.073 615.023c-80.813-27.34-190.644-57.873-291.115-63.151v46.725c95.665 5.016 215.659 36.212 291.115 65.209v-48.783zM434.073 528.398c-80.813-27.34-190.644-57.873-291.115-63.151v46.725c95.665 5.016 215.659 36.212 291.115 65.209v-48.783z"
          ></path>
        </symbol>
        <symbol id="icon-help" viewBox="0 0 1024 1024">
          <title>help</title>
          <path
            class="path1"
            d="M512 97.374c229.144 0 414.626 185.443 414.626 414.626 0 229.146-185.446 414.626-414.626 414.626-229.146 0-414.626-185.446-414.626-414.626 0-229.146 185.443-414.626 414.626-414.626zM512-2.535c-284.171 0-514.535 230.364-514.535 514.535s230.364 514.535 514.535 514.535 514.535-230.364 514.535-514.535-230.364-514.535-514.535-514.535v0zM517.058 820.498c-36.332 0-65.798-29.466-65.798-65.813 0-36.325 29.466-65.78 65.798-65.78 36.34 0 65.795 29.457 65.795 65.78 0 36.347-29.457 65.813-65.795 65.813zM568.786 626.013v11.837c0 0-98.646 0-108.458 0v-11.837c0-33.49 4.885-76.456 43.77-113.81 38.886-37.361 87.482-68.219 87.482-114.885 0-51.592-35.805-78.88-80.95-78.88-75.222 0-80.14 78.014-81.994 95.195h-105.487c2.81-81.351 37.195-195.141 188.119-195.141 130.789 0 189.591 87.597 189.591 169.739 0 130.75-132.067 153.463-132.067 237.787z"
          ></path>
        </symbol>
        <symbol id="icon-man" viewBox="0 0 1024 1024">
          <title>man</title>
          <path
            class="path1"
            d="M890.278 772.95c-147.457-34.012-284.714-63.795-218.24-189.121 202.342-381.477 53.634-585.486-160.036-585.486-217.881 0-362.93 211.839-160.036 585.486 68.469 126.090-73.953 155.841-218.24 189.121-147.397 33.992-136.060 111.706-136.060 252.71h449.503l30.188-154.996h69.288l30.188 154.996h449.503c0-141.007 11.336-218.719-136.055-252.71zM537.345 831.772h-49.915l-39.017-81.733h128.363l-39.428 81.733z"
          ></path>
        </symbol>
        <symbol id="icon-woman" viewBox="0 0 1024 1024">
          <title>woman</title>
          <path
            class="path1"
            d="M370.126 622.15c16.234 34.468 7.242 66.191-16.985 94.166l77.182 307.722h158.607l77.929-312.219c-21.23-26.726-28.474-56.948-12.987-89.67 29.223 47.958 120.14 75.932 190.827 34.219-203.567-51.454 100.16-404.135-166.1-601.956-97.162-72.435-237.787-73.434-333.45 0-266.010 197.823 37.466 550.503-166.1 601.956 70.934 41.711 161.855 12.74 191.078-34.219z"
          ></path>
          <path
            class="path2"
            d="M301.441 912.889h-159.855l110.402-128.134c-37.717 16.985-79.178 30.223-118.145 38.965-147.366 33.72-136.128 129.883-136.128 200.318h331.701l-27.975-111.15z"
          ></path>
          <path
            class="path3"
            d="M890.158 823.718c-41.711-9.491-86.671-23.977-126.388-42.712l113.646 131.88h-159.855l-27.724 111.15h336.446c0-70.436 11.489-166.599-136.128-200.318z"
          ></path>
        </symbol>
        <symbol id="icon-rx" viewBox="0 0 610 1024">
          <title>rx</title>
          <path
            class="path1"
            d="M256 391.191c-11.506-2.876-23.011-2.876-34.517 0-2.876 0-2.876 2.876-2.876 5.753 0 17.258 0 34.517 0 51.775 0 2.876 0 5.753 0 8.629 8.629 0 14.382 0 20.135 0s14.382 0 20.135-2.876c14.382-5.753 23.011-17.258 20.135-31.64 0-20.135-8.629-28.764-23.011-31.64z"
          ></path>
          <path
            class="path2"
            d="M304.899 0c-169.708 0-304.899 20.135-304.899 46.022v0 117.933c0 8.629 20.135 17.258 51.775 25.888v788.135c0 25.888 112.18 46.022 253.124 46.022s253.124-20.135 253.124-46.022v-791.011c34.517-8.629 51.775-17.258 51.775-25.888v-117.933c0-23.011-135.191-43.146-304.899-43.146zM445.843 520.629c0 0-2.876 0 0 0-23.011 23.011-43.146 43.146-63.281 63.281-2.876 2.876-2.876 2.876 0 8.629 17.258 23.011 31.64 48.899 48.899 71.91 0 0 0 2.876 2.876 2.876h-2.876c-17.258 0-31.64 0-48.899 0 0 0-2.876 0-2.876-2.876-5.753-11.506-14.382-20.135-20.135-31.64-2.876-5.753-5.753-8.629-8.629-11.506-14.382 14.382-31.64 28.764-46.022 46.022v0c-17.258 0-37.393 0-54.652 0 0 0 0-2.876 2.876-2.876 23.011-25.888 48.899-48.899 71.91-74.787l2.876-2.876c-11.506-14.382-20.135-28.764-31.64-40.27-14.382-17.258-25.888-31.64-40.27-46.022-8.629-8.629-17.258-11.506-28.764-8.629 0 28.764 0 60.404 0 89.169-17.258 0-34.517 0-51.775 0 0-74.787 0-149.573 0-227.236v0c8.629 0 20.135-2.876 28.764-2.876 5.753 0 14.382 0 20.135-2.876 11.506 0 23.011 0 31.64 0 2.876 0 2.876 0 5.753 0 11.506 0 23.011 2.876 31.64 5.753 28.764 8.629 43.146 28.764 43.146 57.528 0 25.888-14.382 46.022-37.393 57.528h-2.876c23.011 25.888 43.146 54.652 63.281 80.539l2.876-2.876c11.506-11.506 20.135-23.011 31.64-31.64l2.876-2.876c2.876 0 5.753 0 11.506 0 8.629 5.753 23.011 5.753 37.393 8.629 0-2.876 0-2.876 0 0z"
          ></path>
        </symbol>
        <symbol id="icon-ear" viewBox="0 0 631 1024">
          <title>ear</title>
          <path
            class="path1"
            d="M496.991 750.433c112.906 149.404-100.285 258.558-158.614 68.562-17.396-57.306-29.335-116.658-116.317-205.346-148.040-150.769-173.623-380.674 6.481-486.076 125.868-73.679 354.75 7.845 255.829 284.141l96.192 34.452c134.396-374.193-202.617-524.621-403.528-406.939-234.34 137.125-227.859 442.414-27.63 646.396 70.95 71.973 74.361 114.612 92.44 167.142 115.976 334.966 522.233 121.775 344.858-152.474l-89.711 50.143z"
          ></path>
          <path
            class="path2"
            d="M292.669 559.755c23.877 55.259 77.431 100.285 136.442 68.903 62.422-38.204 50.484-156.227-47.755-180.786 48.437-51.166 69.245-111.542 57.647-165.777-8.528-39.568-34.111-70.95-70.609-85.618-38.886-16.032-77.772-14.326-112.565 5.117-44.003 24.56-76.749 76.067-87.323 138.489l70.609 11.939c6.822-40.25 26.606-74.020 51.507-87.664 15.691-8.528 32.064-9.21 50.825-1.364 18.761 7.504 25.583 23.195 27.971 34.452 6.481 30.358-8.187 68.221-39.568 101.65-46.39 47.755-60.035 108.131-37.181 160.661z"
          ></path>
        </symbol>
        <symbol id="icon-glasses" viewBox="0 0 1024 1024">
          <title>glasses</title>
          <path
            class="path1"
            d="M819.139 146.198c-22.739-43.066-51.799-62.975-82.907-62.975-45.43 0-89.735 40.008-95.556 99.968-0.462 5.359 4.105 5.446 8.794 5.446 14.239 0 30.733-8.775 34.338-22.55 7.831-29.897 25.126-42.040 43.882-44.823 23.908-3.539 43.086 12.312 57.95 49.723 24.58 61.869 125.911 328.914 152.713 402.147-57.075-10.805-116.238-18.263-171.43-18.263-48.78 0-101.918 5.527-145.545 21.067-97.898 35.508-119.675 35.968-218.747 0-43.631-15.538-96.766-21.067-145.548-21.067-55.192 0-114.357 7.455-171.428 18.263 26.798-73.238 128.132-340.281 152.713-402.147 14.863-37.414 34.042-53.261 57.952-49.723 18.758 2.783 36.051 14.927 43.882 44.823 3.602 13.777 20.098 22.55 34.338 22.55 4.687 0 9.254-0.084 8.794-5.446-5.821-59.964-50.122-99.968-95.554-99.968-31.112 0-60.17 19.91-82.907 62.975-23.373 44.218-207.405 443.31-207.405 443.31v79.683c36.345 10.973 45.807 26.881 51.59 64.025 16.329 104.808 53.849 207.562 217.359 207.562 130.183 0 173.69-96.893 202.457-186.334 14.571-45.39 71.269-46.565 86.089-0.587 28.891 89.527 72.106 186.922 202.623 186.922 163.515 0 201.034-102.755 217.359-207.562 5.779-37.142 15.247-53.052 51.59-64.025v-79.683c0.002 0-184.030-399.092-207.392-443.31zM437.468 718.433c-22.194 93.205-58.287 179.468-171.050 179.468-141.533 0-170.381-81.566-183.32-213.215-3.139-31.994 3.895-44.555 9.42-51.255 40.658-48.616 255.256-46.648 326.358-3.936 20.389 12.227 33.621 25.753 18.591 88.936zM940.906 684.685c-12.938 131.647-41.786 213.215-183.322 213.215-112.765 0-148.858-86.26-171.050-179.468-15.032-63.188-1.802-76.711 18.591-88.936 71.351-42.879 285.869-44.473 326.36 3.936 5.527 6.698 12.564 19.26 9.42 51.255zM733.425 636.616c65.991-10.050 142.283-1.3 162.549 23.031 4.438 5.274 10.094 15.324 7.58 40.951-1.342 13.567-2.891 26.425-4.815 38.607-17.585-63.734-62.476-97.901-165.314-102.591zM125.261 739.202c-1.925-12.184-3.476-25.039-4.815-38.607-2.513-25.627 3.139-35.675 7.578-40.951 20.266-24.327 96.561-33.079 162.551-23.031-102.839 4.691-147.726 38.857-165.314 102.591z"
          ></path>
        </symbol>
        <symbol id="icon-toothbrush" viewBox="0 0 658 1024">
          <title>toothbrush</title>
          <path
            class="path1"
            d="M644.385 108.839l-5.526-3.364c1.682-11.292-3.364-23.065-13.695-29.072l-56.702-33.877c-13.455-7.929-30.754-3.844-38.923 9.13l-67.514-40.604-9.851-6.007-7.208-4.325-1.442-0.721-170.587 285.672 18.5 11.052 0.481-0.961 67.754 40.604c-3.604 11.052 0 23.305 8.89 30.754l-364.238 577.111c-8.409 13.455-4.325 31.234 8.89 39.884l56.462 35.319c13.455 8.409 31.234 4.325 39.884-8.89l543.715-862.063c8.649-13.214 4.565-31.234-8.89-39.643zM303.212 277.263l11.292-18.981 52.137 17.779-23.786-21.383-17.059-15.377 24.026-40.364 21.383 7.208 30.273 10.331-40.364-36.28 29.793-49.734 20.663 6.968 30.273 10.331-23.786-21.383-16.098-14.656 32.916-55.020 19.942 6.727 30.273 10.331-23.786-21.383-15.617-14.176 5.046-8.409 67.274 40.364-148.242 247.471-66.553-40.364z"
          ></path>
        </symbol>
        <symbol id="icon-health-icons" viewBox="0 0 2294 1024">
          <title>health-icons</title>
          <path
            class="path1"
            d="M2270.779 89.893c-27.359-46.901-74.26-74.26-125.069-74.26v50.809c35.176 0 66.443 19.542 82.076 46.901 15.634 31.267 15.634 66.443 0 93.802l-164.153 289.221c-15.634 31.267-46.901 46.901-82.076 46.901s-66.443-19.542-82.076-46.901l-164.153-285.313c-15.634-31.267-15.634-66.443 0-93.802 15.634-31.267 46.901-46.901 82.076-46.901v-54.718c-50.809 0-101.618 27.359-125.069 74.26-27.359 46.901-27.359 101.618 0 144.611l164.153 285.313c23.45 39.084 58.626 62.534 101.618 70.351v136.794c0 136.794-78.168 246.229-175.878 246.229s-175.878-109.435-175.878-246.229v-164.153c58.626-11.725 101.618-62.534 101.618-125.069 0-70.351-58.626-128.977-128.977-128.977s-128.977 58.626-128.977 128.977c0 62.534 42.992 113.344 101.618 125.069v164.153c0 164.153 101.618 297.038 230.595 297.038 125.069 0 230.595-132.885 230.595-297.038v-136.794c42.992-7.817 78.168-31.267 101.618-70.351l164.153-285.313c19.542-46.901 19.542-101.618-7.817-144.611z"
          ></path>
          <path
            class="path2"
            d="M1090.443 121.16h-300.947v-121.16h-441.649v121.16h-300.947c-23.45 0-46.901 23.45-46.901 46.901v723.053c0 23.45 23.45 46.901 46.901 46.901h426.015v-101.618h183.695v101.618h437.74c23.45 0 46.901-23.45 46.901-46.901v-726.962c-7.817-23.45-27.359-42.992-50.809-42.992zM328.305 695.695h-273.588v-125.069h273.588v125.069zM328.305 480.733h-273.588v-125.069h273.588v125.069zM562.809 19.542c82.076 0 148.519 62.534 140.702 140.702 0 74.26-66.443 140.702-140.702 140.702s-140.702-66.443-140.702-140.702c0-74.26 62.534-140.702 140.702-140.702zM707.42 695.695h-273.588v-125.069h273.588v125.069zM707.42 480.733h-273.588v-125.069h273.588v125.069zM1078.718 695.695h-273.588v-125.069h273.588v125.069zM1078.718 480.733h-273.588v-125.069h273.588v125.069z"
          ></path>
          <path
            class="path3"
            d="M496.366 191.511h42.992v42.992c0 3.908 3.908 11.725 11.725 11.725h35.176c3.908 0 11.725-3.908 11.725-11.725v-42.992h42.992c3.908 0 11.725-3.908 11.725-11.725v-35.176c0-3.908-3.908-11.725-11.725-11.725h-42.992v-42.992c0-3.908-3.908-11.725-11.725-11.725h-35.176c-3.908 0-11.725 3.908-11.725 11.725v42.992h-42.992c-3.908 0-11.725 3.908-11.725 11.725v35.176c0 3.908 3.908 11.725 11.725 11.725z"
          ></path>
        </symbol>
        <symbol id="icon-zoom-out-text" viewBox="0 0 1193 1024">
          <title>zoom-out-text</title>
          <path
            class="path1"
            d="M1027.411 987.077v36.923h-149.095v-36.923h13.645c11.037 0 18.863-10.836 15.251-21.271l-21.672-63.411h-95.517l-24.682-68.026h98.326l-58.394-174.58-49.364 147.69-37.725-106.955 39.531-112.172h95.116l132.44 376.249c4.816 13.445 17.458 22.475 31.705 22.475h10.435zM772.164 958.181h18.461v65.819h-278.324v-65.819h24.080c19.665 0 33.511-19.465 27.090-37.926l-34.916-102.139h-266.685l-34.916 102.139c-6.421 18.662 7.425 37.926 27.090 37.926h24.080v65.819h-278.123v-65.819h18.461c25.485 0 47.959-16.053 56.387-39.933l235.783-669.222h169.162l235.783 669.222c8.428 23.879 31.103 39.933 56.588 39.933zM304.611 686.279h181.402l-90.701-272.304-90.701 272.304z"
          ></path>
          <path
            class="path2"
            d="M1191.356 565.277l-171.971-171.971c25.083-39.732 39.732-86.688 39.732-136.854 0-141.269-114.982-256.251-256.251-256.251s-256.251 114.982-256.251 256.251 114.982 256.251 256.251 256.251c47.759 0 92.708-13.244 131.035-36.12l173.175 173.175 84.28-84.481zM617.249 256.451c0-102.34 83.277-185.616 185.616-185.616s185.616 83.277 185.616 185.616c0 102.34-83.277 185.616-185.616 185.616s-185.616-83.277-185.616-185.616zM910.423 286.953h-208.693v-58.193h208.693v58.193z"
          ></path>
        </symbol>
        <symbol id="icon-zoom-text" viewBox="0 0 1193 1024">
          <title>zoom-text</title>
          <path
            class="path1"
            d="M1027.411 987.077v36.923h-149.095v-36.923h13.645c11.037 0 18.863-10.836 15.251-21.271l-21.672-63.411h-95.517l-24.682-68.026h98.326l-58.394-174.58-49.364 147.69-37.725-106.955 39.531-112.172h95.116l132.44 376.249c4.816 13.445 17.458 22.475 31.705 22.475h10.435zM772.164 958.181h18.461v65.819h-278.324v-65.819h24.080c19.665 0 33.511-19.465 27.090-37.926l-34.916-102.139h-266.685l-34.916 102.139c-6.421 18.662 7.425 37.926 27.090 37.926h24.080v65.819h-278.123v-65.819h18.461c25.485 0 47.959-16.053 56.387-39.933l235.783-669.222h169.162l235.783 669.222c8.428 23.879 31.103 39.933 56.588 39.933zM304.611 686.279h181.402l-90.701-272.304-90.701 272.304z"
          ></path>
          <path
            class="path2"
            d="M1193.162 561.665l-170.968-170.767c25.083-39.331 39.531-86.086 39.531-136.052 0-140.466-114.179-254.646-254.646-254.646s-254.646 114.179-254.646 254.646 114.179 254.646 254.646 254.646c47.558 0 92.106-13.043 130.032-35.919l171.971 171.971 84.079-83.879zM622.667 254.646c0-101.738 82.675-184.412 184.412-184.412s184.412 82.675 184.412 184.412c0 101.738-82.675 184.412-184.412 184.412s-184.412-82.675-184.412-184.412zM914.035 284.946h-74.849v74.849h-57.792v-74.849h-74.849v-57.792h74.849v-74.849h57.792v74.849h74.849v57.792z"
          ></path>
        </symbol>
        <symbol id="icon-facebook" viewBox="0 0 1024 1024">
          <title>facebook</title>
          <path
            class="path1"
            d="M100.946 100v824h824v-824h-824zM648.43 321.316c0 0-45.786 0-63.976 0-22.582 0-27.29 9.25-27.29 32.618 0 19.446 0 56.454 0 56.454h91.266l-8.782 99.102h-82.484v296.060h-118.222v-294.806h-61.48v-100.356h61.48c0 0 0-14.43 0-79.034 0-74.174 39.664-112.904 127.636-112.904 14.41 0 81.852 0 81.852 0v102.866z"
          ></path>
        </symbol>
        <symbol id="icon-pinterest" viewBox="0 0 1024 1024">
          <title>pinterest</title>
          <path
            class="path1"
            d="M100.946 100v824h824v-824h-824zM565.446 652.46c-35.774 0-69.418-19.34-80.926-41.296 0 0-19.246 76.33-23.308 91.070-14.344 52.054-56.544 104.152-59.816 108.418-2.286 2.97-7.336 2.036-7.868-1.894-0.914-6.632-11.668-72.32 0.992-125.904 6.356-26.904 42.614-180.534 42.614-180.534s-10.568-21.154-10.568-52.424c0-49.1 28.462-85.754 63.894-85.754 30.126 0 44.686 22.62 44.686 49.738 0 30.296-19.29 75.59-29.252 117.564-8.314 35.156 17.628 63.82 52.292 63.82 62.76 0 105.038-80.626 105.038-176.154 0-72.602-48.882-126.966-137.85-126.966-100.478 0-163.122 74.952-163.122 158.68 0 28.858 8.528 49.232 21.87 64.972 6.124 7.242 6.982 10.156 4.764 18.466-1.616 6.104-5.238 20.774-6.77 26.588-2.2 8.402-8.998 11.394-16.584 8.292-46.272-18.894-67.846-69.59-67.846-126.574 0-94.11 79.372-206.966 236.778-206.966 126.508 0 209.748 91.522 209.748 189.8-0.004 129.966-72.26 227.058-178.766 227.058z"
          ></path>
        </symbol>
        <symbol id="icon-twitter" viewBox="0 0 1024 1024">
          <title>twitter</title>
          <path
            class="path1"
            d="M100 100v824h824v-824h-824zM741.884 414.25c7.106 158.118-110.816 334.406-319.536 334.406-63.486 0-122.578-18.61-172.326-50.506 59.64 7.030 119.17-9.516 166.434-46.556-49.192-0.906-90.712-33.406-105.016-78.070 17.616 3.368 34.946 2.38 50.766-1.924-54.060-10.866-91.384-59.568-90.166-111.652 15.154 8.422 32.49 13.48 50.92 14.064-50.068-33.46-64.24-99.572-34.792-150.090 55.446 68.012 138.286 112.77 231.714 117.458-16.398-70.32 36.948-138.038 109.51-138.038 32.336 0 61.552 13.65 82.058 35.5 25.608-5.040 49.666-14.396 71.388-27.278-8.394 26.252-26.22 48.282-49.43 62.196 22.74-2.714 44.408-8.758 64.568-17.702-15.068 22.544-34.124 42.344-56.092 58.192z"
          ></path>
        </symbol>
        <symbol id="icon-phone" viewBox="0 0 1024 1024">
          <title>phone</title>
          <path
            class="path1"
            d="M704 640c-64 64-64 128-128 128s-128-64-192-128-128-128-128-192 64-64 128-128-128-256-192-256-192 192-192 192c0 128 131.5 387.5 256 512s384 256 512 256c0 0 192-128 192-192s-192-256-256-192z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-sketch-1" viewBox="0 0 1908 1024">
          <title>arrow</title>
          <path
            class="path1"
            d="M32.637 1018.56c303.256 12.239 648.669 17.679 930.167-116.951 70.714-33.997 135.989-78.874 184.946-140.069 88.393-110.151 80.234-262.459 163.187-375.331 126.47-174.066 365.811-194.465 562.996-183.586-5.44-21.758-10.879-42.157-17.679-63.915-82.954 62.555-165.907 123.75-248.861 186.305-35.357 25.838-1.36 84.313 33.997 58.475 82.954-62.555 165.907-123.75 248.861-186.305 17.679-13.599 25.838-47.596 0-58.475-100.632-44.876-202.624-91.113-303.256-135.989-39.437-17.679-74.794 40.797-33.997 58.475 100.632 44.876 202.624 91.113 303.256 135.989 0-19.039 0-39.437 0-58.475-82.954 62.555-165.907 123.75-248.861 186.305-35.357 25.838-1.36 84.313 33.997 58.475 82.954-62.555 165.907-123.75 248.861-186.305 27.198-20.398 19.039-61.195-17.679-63.915-157.748-8.159-325.015 2.72-465.084 80.234-74.794 40.797-138.709 100.632-180.866 175.426-44.876 81.594-53.036 176.786-91.113 259.74-78.874 172.707-285.578 236.622-456.924 269.259-212.143 40.797-432.446 40.797-647.309 31.278-42.157 0-42.157 67.995 1.36 69.355v0z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-sketch-2" viewBox="0 0 751 1024">
          <title>arrow</title>
          <path
            class="path1"
            d="M58.353 1020.235c161.882-45.176 391.529-116.706 457.412-293.647 35.765-97.882-3.765-186.353-30.118-280.471-47.059-171.294 88.471-257.882 233.412-304.941 20.706-5.647 33.882-24.471 33.882-45.176 0 0 0-1.882 0-1.882 0-56.471-73.412-60.235-92.235-13.176-26.353 65.882-52.706 129.882-79.059 195.765 30.118 7.529 60.235 16.941 90.353 24.471 24.471-67.765 50.824-133.647 75.294-201.412 9.412-22.588-9.412-52.706-32-58.353-65.882-15.059-131.765-28.235-197.647-43.294-58.353-13.176-84.706 77.176-24.471 90.353 65.882 15.059 131.765 28.235 197.647 43.294-11.294-18.824-22.588-39.529-32-58.353-24.471 67.765-50.824 133.647-75.294 201.412-20.706 56.471 67.765 80.941 90.353 24.471 26.353-65.882 52.706-129.882 79.059-195.765-30.118-3.765-62.118-7.529-92.235-13.176 0 0 0 1.882 0 1.882 11.294-15.059 22.588-30.118 33.882-45.176-97.882 32-201.412 79.059-263.529 165.647-69.647 96-52.706 199.529-18.824 304.941 30.118 92.235 39.529 171.294-30.118 242.824-88.471 92.235-227.765 131.765-346.353 165.647-60.235 20.706-35.765 111.059 22.588 94.118v0z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-sketch" viewBox="0 0 750 1024">
          <title>arrow</title>
          <path
            class="path1"
            d="M37.487 1024c219.006-63.137 390.659-177.572 418.281-416.308 9.865-88.786 1.973-179.545 21.703-268.331 29.595-130.22 130.22-197.303 250.574-236.763 35.514-11.838 19.73-69.056-15.784-57.218-203.222 69.056-295.954 195.329-307.792 406.443-3.946 90.759 0 183.491-35.514 268.331-57.218 144.031-209.141 207.168-347.252 246.628-37.487 9.865-21.703 67.083 15.784 57.218v0z"
          ></path>
          <path
            class="path2"
            d="M728.046 45.38c-69.056-15.784-138.112-29.595-207.168-45.38-37.487-7.892-53.272 49.326-15.784 57.218 69.056 15.784 138.112 29.595 207.168 45.38 35.514 7.892 51.299-49.326 15.784-57.218v0z"
          ></path>
          <path
            class="path3"
            d="M690.559 63.137c-27.622 69.056-55.245 136.139-82.867 205.195-13.811 35.514 43.407 51.299 57.218 15.784 27.622-69.056 55.245-136.139 82.867-205.195 13.811-35.514-43.407-49.326-57.218-15.784v0z"
          ></path>
        </symbol>
        <symbol id="icon-chevron-right" viewBox="0 0 1024 1024">
          <title>chevron-right</title>
          <path
            class="path1"
            d="M269.916 57.187c-55.378 55.378-55.378 145.151 0 200.53l254.208 254.281-254.208 254.281c-55.378 55.378-55.378 145.151 0 200.53 27.652 27.725 63.957 41.555 100.268 41.555s72.61-13.829 100.268-41.555l454.881-454.813-454.881-454.813c-55.309-55.378-145.221-55.378-200.53 0z"
          ></path>
        </symbol>
        <symbol id="icon-chevron-left" viewBox="0 0 1024 1024">
          <title>chevron-left</title>
          <path
            class="path1"
            d="M754.084 57.187c55.378 55.378 55.378 145.151 0 200.53l-254.208 254.281 254.208 254.281c55.378 55.378 55.378 145.151 0 200.53-27.652 27.725-63.957 41.555-100.268 41.555s-72.61-13.829-100.268-41.555l-454.881-454.813 454.881-454.813c55.309-55.378 145.221-55.378 200.53 0z"
          ></path>
        </symbol>
        <symbol id="icon-chat" viewBox="0 0 1024 1024">
          <title>chat</title>
          <path
            class="path1"
            d="M402 220c-58 0-112 10-164 30-50 18-90 46-120 80s-44 70-44 108c0 32 10 62 30 92 20 28 48 54 84 74l56 32-20 48c12-8 24-14 36-22l24-18 30 6c30 6 60 8 88 8 58 0 114-10 164-30s90-46 120-80c30-34 46-70 46-110 0-38-16-74-46-108s-70-62-120-80c-50-20-106-30-164-30zM402 146c74 0 140 14 202 40s112 62 146 106c36 44 54 94 54 146 0 54-18 102-54 148-34 44-84 80-146 106s-128 40-202 40c-32 0-66-4-100-10-48 34-100 58-160 74-12 2-30 6-48 8h-2c-4 0-8 0-12-4s-6-8-6-12c0-2 0-2 0-4s0-2 0-4c0 0 0-2 0-2l2-4c0 0 0 0 2-2s2-4 2-4c0 0 2 0 2-2 2-2 4-4 4-4 2-2 6-6 12-14 8-6 12-12 16-16 2-4 6-10 12-16 6-8 10-16 14-22 4-8 8-16 12-26-48-28-84-62-112-102-26-40-40-82-40-128 0-52 18-102 54-146s84-80 146-106c62-26 130-40 202-40zM872 814c4 10 8 18 12 26s8 14 14 22c6 6 10 12 12 16 4 4 8 10 16 16 6 8 10 12 12 16 2 0 2 0 4 2 0 2 2 2 2 2 0 2 2 2 2 4 0 0 2 2 2 2l2 4c0 0 0 0 0 2 2 4 2 4 0 4 0 0 0 2 0 4 0 6-4 10-8 12-2 4-8 6-12 4-18-2-36-4-48-8-60-16-112-40-160-74-34 6-68 10-100 10-104 0-194-26-270-76 22 2 38 2 50 2 62 0 120-8 176-26 58-16 108-40 152-72 48-36 84-76 110-122 24-46 38-94 38-146 0-28-4-58-14-86 50 28 88 60 118 102 28 40 42 84 42 132 0 44-14 88-40 128-28 40-64 72-112 100z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-up" viewBox="0 0 1024 1024">
          <title>icon-arrow-up</title>
          <path
            class="path1"
            d="M638.857 500.571q-4.571 11.429-17.143 11.429h-109.714v201.143q0 8-5.143 13.143t-13.143 5.143h-109.714q-8 0-13.143-5.143t-5.143-13.143v-201.143h-109.714q-8 0-13.143-5.143t-5.143-13.143q0-6.857 5.714-13.714l182.286-182.286q6.286-5.143 13.143-5.143t13.143 5.143l182.857 182.857q8.571 9.143 4 20zM438.857 201.143q-84.571 0-156 41.714t-113.143 113.143-41.714 156 41.714 156 113.143 113.143 156 41.714 156-41.714 113.143-113.143 41.714-156-41.714-156-113.143-113.143-156-41.714zM877.714 512q0 119.429-58.857 220.286t-159.714 159.714-220.286 58.857-220.286-58.857-159.714-159.714-58.857-220.286 58.857-220.286 159.714-159.714 220.286-58.857 220.286 58.857 159.714 159.714 58.857 220.286z"
          ></path>
        </symbol>
        <symbol id="icon-plus" viewBox="0 0 1024 1024">
          <title>plus</title>
          <path
            fill="#C72027"
            d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z"
          ></path>
          <path
            fill="#fff"
            d="M271.927 146.202c59.39-41.083 123.184-51.765 142.488-23.859s-13.192 83.833-72.581 124.916c-59.39 41.083-123.184 51.765-142.488 23.859s13.192-83.833 72.581-124.916z"
          ></path>
          <path
            fill="#fff"
            d="M740.431 425.354h-141.785v-141.785h-173.292v141.785h-141.785v173.292h141.785v141.785h173.292v-141.785h141.785z"
          ></path>
        </symbol>
        <symbol id="icon-plus-alt" viewBox="0 0 1024 1024">
          <title>plus-alt</title>
          <path
            class="path1"
            d="M512 0c-281.994 0-512 230.006-512 512s230.006 512 512 512c281.994 0 512-230.006 512-512s-230.006-512-512-512zM200.074 270.966c-18.905-28.357 14.178-83.495 72.468-126.031s124.455-51.988 143.36-23.631c18.905 28.357-14.178 83.495-72.468 126.031s-124.455 51.988-143.36 23.631zM740.431 598.646h-141.785v141.785h-173.292v-141.785h-141.785v-173.292h141.785v-141.785h173.292v141.785h141.785v173.292z"
          ></path>
        </symbol>
        <symbol id="icon-search" viewBox="0 0 973 1024">
          <title>search</title>
          <path
            class="path1"
            d="M960 832l-249.125-249.125c35.563-58.063 57.125-125.719 57.125-198.875 0-212.031-172-384-384-384-212.031 0-384 171.969-384 384 0 212 171.969 384 384 384 73.156 0 140.812-21.562 198.875-57l249.125 249c17.5 17.5 46.5 17.375 64 0l64-64c17.5-17.5 17.5-46.5 0-64zM384 640c-141.375 0-256-114.625-256-256s114.625-256 256-256 256 114.625 256 256-114.625 256-256 256z"
          ></path>
        </symbol>
        <symbol id="icon-chevron-left" viewBox="0 0 717 1024">
          <title>chevron-left</title>
          <path
            class="path1"
            d="M512.2 0l204.8 204.8-307.2 307.2 307.2 307.2-204.8 204.8-512-512 512-512z"
          ></path>
        </symbol>
        <symbol id="icon-heart" viewBox="0 0 40 35">
          <title>heart</title>
          <path
            class="path1"
            d="M32.630,19.057 C32.630,19.057 19.445,32.340 19.445,32.340 C19.445,32.340 6.257,19.052 6.257,19.052 C3.457,16.273 1.915,13.015 1.915,9.878 C1.915,5.036 5.024,1.908 9.834,1.908 C14.095,1.908 15.651,3.725 18.229,6.736 C18.229,6.736 18.714,7.300 18.714,7.300 C18.896,7.513 19.163,7.635 19.445,7.635 C19.726,7.635 19.993,7.513 20.176,7.300 C20.176,7.300 20.664,6.731 20.664,6.731 C23.241,3.723 24.796,1.908 29.055,1.908 C33.867,1.908 36.975,5.036 36.975,9.878 C36.975,13.015 35.434,16.273 32.630,19.057 ZM29.055,-0.003 C24.068,-0.003 21.962,2.273 19.445,5.206 C16.928,2.273 14.820,-0.003 9.834,-0.003 C3.947,-0.003 -0.009,3.968 -0.009,9.878 C-0.009,13.525 1.733,17.263 4.890,20.398 C4.890,20.398 18.759,34.371 18.759,34.371 C18.940,34.553 19.187,34.656 19.445,34.656 C19.702,34.656 19.949,34.553 20.130,34.371 C20.130,34.371 33.996,20.403 33.996,20.403 C37.158,17.263 38.900,13.526 38.900,9.878 C38.900,3.968 34.944,-0.003 29.055,-0.003 Z"
          ></path>
        </symbol>
        <symbol id="icon-check" viewBox="0 0 1024 1024">
          <title>check</title>
          <path
            class="path1"
            d="M424.653 870.298c-22.272 0-43.366-10.394-56.883-28.314l-182.938-241.715c-23.808-31.386-17.613-76.083 13.824-99.891 31.488-23.91 76.186-17.613 99.994 13.824l120.371 158.925 302.643-485.99c20.838-33.382 64.87-43.622 98.355-22.784 33.434 20.787 43.725 64.819 22.835 98.304l-357.581 573.952c-12.39 20.019-33.843 32.512-57.344 33.587-1.126 0.102-2.15 0.102-3.277 0.102z"
          ></path>
        </symbol>
        <symbol id="icon-close" viewBox="0 0 1024 1024">
          <title>close</title>
          <path
            class="path1"
            d="M512 0c-282.752 0-512 229.248-512 512s229.248 512 512 512 512-229.248 512-512-229.248-512-512-512zM765.248 674.752l-90.496 90.496-162.752-162.752-162.752 162.752-90.496-90.496 162.752-162.752-162.752-162.752 90.496-90.496 162.752 162.752 162.752-162.752 90.496 90.496-162.752 162.752 162.752 162.752z"
          ></path>
        </symbol>
        <symbol id="icon-alert" viewBox="0 0 1103 1024">
          <title>alert</title>
          <path
            class="path1"
            d="M889.608 812.239c129.841-82.991 213.367-212.564 213.367-358.467 0-250.579-246.831-453.773-551.488-453.773s-551.488 203.194-551.488 453.773c0 265.838 277.35 476.796 603.424 451.899 140.817 110.565 326.341 125.289 430.749 116.187-94.77-23.023-184.454-108.691-144.565-209.619zM541.582 723.894c-46.047 0-69.070-23.023-69.070-69.070s23.023-69.070 69.070-69.070c46.047 0 69.070 23.023 69.070 69.070s-23.023 69.070-69.070 69.070zM619.219 247.902l-48.188 280.295h-58.897l-48.188-280.295c-0.803-4.819-1.071-9.37-1.071-14.189 0-40.157 26.236-60.503 78.707-60.503s78.707 20.078 78.707 59.968c0.268 5.622-0.268 10.441-1.071 14.724z"
          ></path>
        </symbol>
        <symbol id="icon-circle-plus" viewBox="0 0 1024 1024">
          <title>circle-plus</title>
          <path
            class="path1"
            d="M512 81.92c-237.517 0-430.080 192.563-430.080 430.080s192.563 430.080 430.080 430.080 430.080-192.563 430.080-430.080c0-237.517-192.563-430.080-430.080-430.080zM768 563.2h-204.8v204.8h-102.4v-204.8h-204.8v-102.4h204.8v-204.8h102.4v204.8h204.8v102.4z"
          ></path>
        </symbol>
        <symbol id="icon-circle-minus" viewBox="0 0 1024 1024">
          <title>circle-minus</title>
          <path
            class="path1"
            d="M512 81.92c-237.517 0-430.080 192.563-430.080 430.080s192.563 430.080 430.080 430.080 430.080-192.563 430.080-430.080c0-237.517-192.563-430.080-430.080-430.080zM768 563.2h-512v-102.4h512v102.4z"
          ></path>
        </symbol>
      </defs>
    </svg>

    <h2>Free and simple quote</h2>
    <hr />
    <div v-if="step1">
      <h4>Compare Plans & Rates</h4>

      <b-container fluid>
        <b-row
          align-h="center"
          class="mt-4"
          style="text-align: center !important"
        >
          <b-col
            cols="12"
            lg="6"
            class="py-4 mb-3"
            style="background-color: #fffaf2"
          >
            <b-form @submit.stop.prevent="onSubmit">
              <b-form-row class="mt-2">
                <b-col cols="12">
                  <label for="zipcode">Zip Code:</label>
                  <b-form-input
                    id="zipcode"
                    ref="zipcode"
                    v-model="$v.zip.zipcode.$model"
                    :state="validateState('zipcode')"
                    aria-describedby="zipcode-help zip-feedback"
                    trim
                    v-mask="'#####'"
                  ></b-form-input>
                  <b-form-invalid-feedback id="zipcode-live-feedback"
                    >This is a required field</b-form-invalid-feedback
                  >
                </b-col>

                <b-col cols="12">
                  <label for="county">County:</label>
                  <b-form-select
                    id="county"
                    name="county"
                    ref="county"
                    :options="counties"
                    v-model="$v.zip.county.$model"
                    :state="validateState('county')"
                    aria-describedby="county-live-feedback"
                  ></b-form-select>
                  <b-form-invalid-feedback id="county-live-feedback"
                    >This is a required field</b-form-invalid-feedback
                  >
                </b-col>

                <b-col cols="12" align-self="end">
                  <b-button
                    block
                    class="mt-3 btn-primary--alt"
                    size="md"
                    type="submit"
                    >Find My Rates</b-button
                  >
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div v-if="step2">
      <b-tabs content-class="mt-3">
        <b-tab :title="isMobile() ? 'MS' : 'Medicare Supplement'" active>
          <div v-if="show_demographics">
            <h5>Some additional details are needed to display these quotes.</h5>
            <b-form @submit.stop.prevent="onSubmit2">
              <b-container fluid>
                <b-row
                  align-h="center"
                  class="mt-4"
                  style="text-align: center !important"
                >
                  <b-col
                    cols="12"
                    lg="6"
                    class="py-4 mb-3"
                    style="background-color: #fffaf2"
                  >
                    <h5>My Profile</h5>
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="date_of_birth"
                          >Date Of Birth (MM-DD-YYYY):</label
                        >
                        <b-form-input
                          id="date_of_birth"
                          ref="date_of_birth"
                          v-model="$v.demographics.date_of_birth.$model"
                          :state="validateState2('date_of_birth')"
                          aria-describedby="date_of_birth-help date_of_birth-feedback"
                          trim
                          v-mask="'##-##-####'"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="date_of_birth-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <b-row class="mt-2">
                      <b-col>
                        <label for="gender">Gender:</label>
                        <b-form-select
                          size="sm"
                          id="gender"
                          name="gender"
                          ref="gender"
                          :options="genders"
                          v-model="$v.demographics.gender.$model"
                          :state="validateState2('gender')"
                          aria-describedby="gender-live-feedback"
                        ></b-form-select>

                        <b-form-invalid-feedback id="gender-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <label for="tobacco">Tobacco User:</label>
                        <b-form-select
                          size="sm"
                          id="tobacco"
                          name="tobacco"
                          ref="tobacco"
                          :options="tobaccos"
                          v-model="$v.demographics.tobacco.$model"
                          :state="validateState2('tobacco')"
                          aria-describedby="tobacco-live-feedback"
                        ></b-form-select>

                        <b-form-invalid-feedback id="tobacco-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-row>
                    <b-col>
                      <b-button
                        block
                        class="mt-3"
                        style="background-color: #1e5191 !important"
                        size="md"
                        variant="primary"
                        v-if="!show_spouse"
                        @click="
                          show_spouse = true;
                          spouse_set = 1;
                        "
                        >Add A Spouse / Housemate</b-button
                      >
                    </b-col>
                  </b-col>

                  <b-col
                    v-if="show_spouse"
                    cols="12"
                    lg="6"
                    class="py-4"
                    style="background-color: #fffaf2"
                  >
                    <h5>Spouse / Housemate Profile</h5>
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="sdate_of_birth"
                          >Date Of Birth (MM-DD-YYYY):</label
                        >
                        <b-form-input
                          id="sdate_of_birth"
                          ref="sdate_of_birth"
                          v-model="$v.demographics.sdate_of_birth.$model"
                          :state="validateState2('sdate_of_birth')"
                          aria-describedby="sdate_of_birth-help sdate_of_birth-feedback"
                          trim
                          v-mask="'##-##-####'"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="sdate_of_birth-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <b-row class="mt-2">
                      <b-col>
                        <label for="sgender">Gender:</label>
                        <b-form-select
                          size="sm"
                          id="sgender"
                          name="sgender"
                          ref="sgender"
                          :options="genders"
                          v-model="$v.demographics.sgender.$model"
                          :state="validateState2('sgender')"
                          aria-describedby="sgender-live-feedback"
                        ></b-form-select>

                        <b-form-invalid-feedback id="sgender-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <label for="stobacco">Tobacco User:</label>
                        <b-form-select
                          size="sm"
                          id="stobacco"
                          name="stobacco"
                          ref="stobacco"
                          :options="tobaccos"
                          v-model="$v.demographics.stobacco.$model"
                          :state="validateState2('stobacco')"
                          aria-describedby="stobacco-live-feedback"
                        ></b-form-select>

                        <b-form-invalid-feedback id="stobacco-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-row>
                    <b-col>
                      <b-button
                        block
                        class="mt-3"
                        style="background-color: #1e5191 !important"
                        size="md"
                        variant="primary"
                        @click="
                          show_spouse = false;
                          spouse_set = 0;
                        "
                        >Remove Spouse / Housemate</b-button
                      >
                    </b-col>
                  </b-col>
                </b-row>
                <b-row
                  align-h="center"
                  class="mt-4"
                  style="text-align: center !important"
                >
                  <b-col>
                    <span class="mmfancy">Did You Know?</span>
                    <p>
                      By adding a spouse/housemate you may qualify for
                      discounts, even if they do not apply.
                    </p>
                  </b-col>
                </b-row>
                <b-row
                  align-h="center"
                  class="mt-4"
                  style="text-align: center !important"
                >
                  <b-col cols="12" lg="6">
                    <b-button
                      block
                      class="mt-3 btn-primary--alt"
                      size="md"
                      type="submit"
                      variant="primary"
                      >Get My MediGap Rates</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </div>
          <div v-if="show_contact">
            <h5>Just one step away from your personalised quote.</h5>
            <b-form @submit.stop.prevent="onSubmit3">
              <b-container fluid>
                <b-row
                  align-h="center"
                  class="mt-4"
                  style="text-align: center !important"
                >
                  <b-col
                    cols="12"
                    lg="6"
                    class="py-4 mb-3"
                    style="background-color: #fffaf2"
                  >
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="first_name">First Name:</label>
                        <b-form-input
                          id="first_name"
                          ref="first_name"
                          v-model="$v.contact.first_name.$model"
                          :state="validateState3('first_name')"
                          aria-describedby="first_name-help first_name-feedback"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="first_name-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="first_name">Last Name:</label>
                        <b-form-input
                          id="last_name"
                          ref="last_name"
                          v-model="$v.contact.last_name.$model"
                          :state="validateState3('last_name')"
                          aria-describedby="last_name-help last_name-feedback"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="last_name-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="phone">Phone:</label>
                        <b-form-input
                          id="phone"
                          ref="phone"
                          v-model="$v.contact.phone.$model"
                          :state="validateState3('phone')"
                          aria-describedby="phone-help phone-feedback"
                          trim
                          v-mask="'(###)###-####'"
                        ></b-form-input>
                        <b-form-invalid-feedback id="phone-live-feedback"
                          >Please provide a valid and existing telephone
                          number</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <b-form-row class="mt-2">
                      <b-col>
                        <label for="email">Email:</label>
                        <b-form-input
                          id="email"
                          ref="email"
                          v-model="$v.contact.email.$model"
                          :state="validateState3('email')"
                          aria-describedby="email-help email-feedback"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="email-live-feedback"
                          >Please provide a valid existing email
                          address</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-form-row>
                    <hr />
                    <div v-if="show_spouse">
                      <h5>Spouse / Housemate Details</h5>
                      <b-form-row class="mt-2">
                        <b-col>
                          <label for="sfirst_name">Spouse First Name:</label>
                          <b-form-input
                            id="sfirst_name"
                            ref="sfirst_name"
                            v-model="$v.contact.sfirst_name.$model"
                            :state="validateState3('sfirst_name')"
                            aria-describedby="sfirst_name-help sfirst_name-feedback"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="sfirst_name-live-feedback"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-col>
                      </b-form-row>
                      <b-form-row class="mt-2">
                        <b-col>
                          <label for="slast_name">SpouseLast Name:</label>
                          <b-form-input
                            id="slast_name"
                            ref="slast_name"
                            v-model="$v.contact.slast_name.$model"
                            :state="validateState3('slast_name')"
                            aria-describedby="slast_name-help slast_name-feedback"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback id="slast_name-live-feedback"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-col>
                      </b-form-row>
                    </div>
                    <b-form-row>
                      <b-col>
                        <b-button
                          block
                          class="mt-3 btn-primary--alt"
                          size="md"
                          type="submit"
                          variant="primary"
                          >View my rates</b-button
                        >
                      </b-col>
                    </b-form-row>
                    <p><br/>By completing this form, you agree to our <a href="https://www.medicaremall.com/terms-of-use" target="_blank">Terms of Use</a></p>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </div>

          <div v-if="results_loader" class="text-center">
            <b-spinner variant="primary"></b-spinner>
            <p>Please Wait While We Load Your Rates</p>
          </div>

          <div v-if="show_results">
            <b-tabs>
              <b-tab :title="contact.first_name + ' ' + contact.last_name">
                <b-container>
                  <b-row style="background: #d7e7fc" class="py-2">
                    <b-col><h5>Company</h5></b-col>
                    <b-col><h5>Sort By</h5> </b-col>
                    <b-col>
                      <b-form-select
                        size="sm"
                        id="tobacco"
                        name="tobacco"
                        ref="tobacco"
                        :options="sortplans"
                        v-model="sortby"
                        @change="sort_plans()"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(item, index) in medsuprates"
                    :key="index"
                    class="msresults py-4"
                  >
                    <b-col cols="12" lg="4">
                      <h6>{{ item.name }}</h6>
                      <img :src="item['img']" alt="" />
                    </b-col>
                    <b-col>
                      <b-container>
                        <b-row>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>A</h4>
                            <p>
                              {{ item.A == "" ? "N/A" : "$" + String(item.A) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.A +
                                '&plan=A'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>F</h4>
                            <p>
                              {{ item.F == "" ? "N/A" : "$" + String(item.F) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.F +
                                '&plan=F'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>G</h4>
                            <p>
                              {{ item.G == "" ? "N/A" : "$" + String(item.G) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.G +
                                '&plan=G'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>N</h4>
                            <p>
                              {{ item.N == "" ? "N/A" : "$" + String(item.N) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.N +
                                '&plan=N'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab
                v-if="show_spouse"
                :title="contact.sfirst_name + ' ' + contact.slast_name"
              >
                <b-container>
                  <b-row style="background: #d7e7fc" class="py-2">
                    <b-col><h5>Company</h5></b-col>
                    <b-col><h5>Sort By</h5> </b-col>
                    <b-col>
                      <b-form-select
                        size="sm"
                        id="tobacco"
                        name="tobacco"
                        ref="tobacco"
                        :options="sortplans"
                        v-model="sortby2"
                        @change="sort_plans2()"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(item, index) in medsuprates2"
                    :key="index"
                    class="msresults py-4"
                  >
                    <b-col cols="12" lg="4">
                      <h6>{{ item.name }}</h6>
                      <img :src="item['img']" alt="" />
                    </b-col>
                    <b-col>
                      <b-container>
                        <b-row>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>A</h4>
                            <p>
                              {{ item.A == "" ? "N/A" : "$" + String(item.A) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.A +
                                '&plan=A'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>F</h4>
                            <p>
                              {{ item.F == "" ? "N/A" : "$" + String(item.F) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.F +
                                '&plan=F'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>G</h4>
                            <p>
                              {{ item.G == "" ? "N/A" : "$" + String(item.G) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.G +
                                '&plan=G'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                            class="msrbadge py-3 mx-auto my-1"
                          >
                            <h6>PLAN</h6>
                            <h4>N</h4>
                            <p>
                              {{ item.N == "" ? "N/A" : "$" + String(item.N) }}
                            </p>
                            <b-button
                              size="sm"
                              squared
                              block
                              class="btn-primary--alt"
                              :href="
                                item.url +
                                '&company=' +
                                item.name +
                                '&amnt=' +
                                item.N +
                                '&plan=N'
                              "
                              >Enroll Now</b-button
                            >
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
            </b-tabs>
          </div>
        </b-tab>
        <b-tab :title="isMobile() ? 'MA' : 'Medicare Advantage'">
          <h3>Medicare Advantage</h3>
          For the latest Medicare Advantage Rates please call our offices and a
          friendly agent will assist you.
        </b-tab>
        <b-tab :title="isMobile() ? 'DVH' : 'Dental, Vision & Hearing'">
          <b-container>
            <b-row style="background: #d7e7fc" class="py-3" v-if="!isMobile()">
              <b-col><b>Plan Name</b></b-col>
              <b-col><b>Dental</b></b-col>
              <b-col><b>Vision</b></b-col>
              <b-col><b>Hearing</b></b-col>
              <b-col><b>Other Benefits</b></b-col>
              <b-col></b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="2" align-self="center">
                <h3 class="mt-3"><b style="color: #174176">DVRX+</b></h3>
              </b-col>
              <b-col cols="4" lg="2" align-self="center">
                <svg class="icon">
                  <use xlink:href="#icon-toothbrush"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2" align-self="center">
                <svg class="icon"><use xlink:href="#icon-glasses"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2" align-self="center">
                <svg class="icon"><use xlink:href="#icon-ear"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="12" lg="2" align-self="center">
                <ul class="list-unstyled">
                  <li class="liplus">+</li>
                  <li>Fitness</li>
                  <li>Psychological &amp; Legal Counseling</li>
                </ul>
              </b-col>
              <b-col cols="12" lg="2" align-self="center">
                <p><b>starting at: $16 /mo*</b></p>
                <b-button
                  class="btn-primary--alt"
                  block
                  size="sm"
                  target="_blank"
                  href="http://www.healthperxplus.com"
                  >Learn More</b-button
                >
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="12" lg="2">
                <h3 class="mt-3"><b style="color: #174176">DRX+</b></h3>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon">
                  <use xlink:href="#icon-toothbrush"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon icon--na">
                  <use xlink:href="#icon-glasses"></use></svg><br />
                <svg class="icon icon--nocheck icon--indicate">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon"><use xlink:href="#icon-ear"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="12" lg="2">
                <ul class="list-unstyled">
                  <li class="liplus">+</li>
                  <li>Fitness</li>
                  <li>Psychological &amp; Legal Counseling</li>
                </ul>
              </b-col>
              <b-col cols="12" lg="2">
                <p><b>starting at: $14 /mo*</b></p>
                <b-button
                  class="btn-primary--alt"
                  block
                  size="sm"
                  target="_blank"
                  href="http://www.healthperxplus.com"
                  >Learn More</b-button
                >
              </b-col>
            </b-row>

            <hr />
            <b-row>
              <b-col cols="12" lg="2">
                <h3 class="mt-3"><b style="color: #174176">VRX+</b></h3>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon icon--na">
                  <use xlink:href="#icon-toothbrush"></use></svg><br />
                <svg class="icon icon--nocheck icon--indicate">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon">
                  <use xlink:href="#icon-glasses"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="4" lg="2">
                <svg class="icon"><use xlink:href="#icon-ear"></use></svg><br />
                <svg class="icon icon--check icon--indicate">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </b-col>
              <b-col cols="12" lg="2">
                <ul class="list-unstyled">
                  <li class="liplus">+</li>
                  <li>Fitness</li>
                  <li>Psychological &amp; Legal Counseling</li>
                </ul>
              </b-col>
              <b-col cols="12" lg="2">
                <p><b>starting at: $11 /mo*</b></p>
                <b-button
                  class="btn-primary--alt"
                  block
                  size="sm"
                  target="_blank"
                  href="http://www.healthperxplus.com"
                  >Learn More</b-button
                >
              </b-col>
            </b-row>
            <b-row style="background: #d7e7fc" class="py-3">
              <b-col> *All plans have a one time $25 application fee. </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Form",
  props: {
    msg: String,
  },
  mixins: [validationMixin],
  data() {
    return {
      sortby: "",
      sortby2: "",
      main_id: 0,
      spouse_id: 0,
      sortplans: ["Plan A", "Plan F", "Plan G", "Plan N"],
      results_loader: false,
      results_loader2: false,
      show_results: false,
      show_results2: false,
      show_demographics: false,
      show_contact: false,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      medsuprates: [],
      medsuprates2: [],
      spouse_set: 0,
      show_spouse: false,
      counties: [],
      state: "",
      zip: {
        zipcode: "",
        county: "",
      },
      demographics: {
        gender: "",
        date_of_birth: "",
        tobacco: "",
        sgender: "",
        sdate_of_birth: "",
        stobacco: "",
      },
      contact: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        sfirst_name: "",
        slast_name: "",
      },
      genders: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      tobaccos: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
    };
  },
  validations: {
    contact: {
      first_name: { required },
      last_name: { required },
      phone: {
        isUnique(value) {
          return new Promise((resolve, reject) => {
            if (value.length == 13) {
              axios
                .post(
                  "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/micro_phone_check",
                  { phone: value }
                )
                .then((result) => {
                  console.log(result);
                  resolve(result.data);
                });
            } else {
              resolve(false);
            }
          });
        },
      },
      email: {
        isUnique(value) {
          return new Promise((resolve, reject) => {
            var re = /\S+@\S+\.\S+/;
            if (re.test(value)) {
              axios
                .post(
                  "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/micro_email_check",
                  { email: value }
                )
                .then((result) => {
                  console.log(result);
                  resolve(result.data);
                });
            } else {
              resolve(false);
            }
          });
        },
      },
      sfirst_name: {
        required: requiredIf(function () {
          return this.show_spouse;
        }),
      },
      slast_name: {
        required: requiredIf(function () {
          return this.show_spouse;
        }),
      },
    },
    zip: {
      zipcode: { required, minLength: minLength(5) },
      county: { required },
    },
    demographics: {
      gender: { required },
      date_of_birth: { required },
      tobacco: { required },
      sgender: {
        required: requiredIf(function () {
          return this.show_spouse;
        }),
      },
      sdate_of_birth: {
        required: requiredIf(function () {
          return this.show_spouse;
        }),
      },
      stobacco: {
        required: requiredIf(function () {
          return this.show_spouse;
        }),
      },
    },
  },
  watch: {
    zip: {
      handler(val) {
        if (val.zipcode.length == 5) {
          this.get_county();
        }
      },
      deep: true,
    },
  },
  created() {
    var ism = this.isMobile();
    console.log(ism);

    var myage = moment().diff(moment('27-12-1975', "DD-MM-YYYY"), 'years');
    console.log("MYAGE");
    console.log(myage);
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    get_county() {
      axios
        .get(
          "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/get_counties/" +
            this.zip.zipcode
        )
        .then((result) => {
          this.counties = result.data.counties;
          this.state = result.data.state;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    stepper(step) {
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;

      switch (step) {
        case 1:
          this.step1 = true;
          break;
        case 2:
          this.step2 = true;
          break;
        case 3:
          this.step3 = true;
          break;
        case 4:
          this.step4 = true;
          break;
        // code block
      }
    },
    onSubmit() {
      this.$v["zip"].$touch();
      console.log(this.$v.$invalid);
      if (this.$v["zip"].$invalid) {
        // 1. Loop the keys
        for (let key in Object.keys(this.$v["zip"])) {
          // 2. Extract the input
          const input = Object.keys(this.$v["zip"])[key];
          // 3. Remove special properties
          if (input.includes("$")) return false;

          // 4. Check for errors
          if (this.$v["zip"][input].$error) {
            // 5. Focus the input with the error
            this.$refs[input].focus();

            // 6. Break out of the loop
            break;
          }
        }
      } else {
        this.stepper(2);
        this.show_demographics = true;
        window.scrollTo(0, 0);
      }
    },
    onSubmit2() {
      this.$v["demographics"].$touch();
      console.log(this.$v.$invalid);
      if (this.$v["demographics"].$invalid) {
        // 1. Loop the keys
        for (let key in Object.keys(this.$v["demographics"])) {
          // 2. Extract the input
          const input = Object.keys(this.$v["demographics"])[key];
          // 3. Remove special properties
          if (input.includes("$")) return false;

          // 4. Check for errors
          if (this.$v["demographics"][input].$error) {
            // 5. Focus the input with the error
            this.$refs[input].focus();

            // 6. Break out of the loop
            break;
          }
        }
      } else {
        //this.stepper(3);

        this.show_demographics = false;
        this.show_contact = true;
        this.$nextTick(() => {
          this.contact.phone = "";
        });
        window.scrollTo(0, 0);
      }
    },
    onSubmit3() {
      this.$v["contact"].$touch();
      console.log(this.$v.$invalid);
      if (this.$v["contact"].$invalid) {
        // 1. Loop the keys
        for (let key in Object.keys(this.$v["contact"])) {
          // 2. Extract the input
          const input = Object.keys(this.$v["contact"])[key];
          // 3. Remove special properties
          if (input.includes("$")) return false;

          // 4. Check for errors
          if (this.$v["contact"][input].$error) {
            // 5. Focus the input with the error
            this.$refs[input].focus();

            // 6. Break out of the loop
            break;
          }
        }
      } else {
        //this.stepper(3);

        this.show_contact = false;
        this.results_loader = true;
        this.add_lead();
        //this.get_medsupp_rates("main")date_of_birth
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.zip[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.demographics[name];
      return $dirty ? !$error : null;
    },
    validateState3(name) {
      const { $dirty, $error } = this.$v.contact[name];
      return $dirty ? !$error : null;
    },
    sort_plans() {
      console.log("Sorting");
      console.log(this.sortby);
      if (this.sortby == "Plan A") {
        this.medsuprates.sort((a, b) => Number(a.A) - Number(b.A));
      }

      if (this.sortby == "Plan F") {
        this.medsuprates.sort((a, b) => Number(a.F) - Number(b.F));
      }

      if (this.sortby == "Plan G") {
        this.medsuprates.sort((a, b) => Number(a.G) - Number(b.G));
      }

      if (this.sortby == "Plan N") {
        this.medsuprates.sort((a, b) => Number(a.N) - Number(b.N));
      }
    },
    sort_plans2() {
      console.log("Sorting");
      console.log(this.sortby);
      if (this.sortby2 == "Plan A") {
        this.medsuprates2.sort((a, b) => Number(a.A) - Number(b.A));
      }

      if (this.sortby2 == "Plan F") {
        this.medsuprates2.sort((a, b) => Number(a.F) - Number(b.F));
      }

      if (this.sortby2 == "Plan G") {
        this.medsuprates2.sort((a, b) => Number(a.G) - Number(b.G));
      }

      if (this.sortby2 == "Plan N") {
        this.medsuprates2.sort((a, b) => Number(a.N) - Number(b.N));
      }
    },
    add_lead() {
      this.sho;
      var data = {
        zipcode: this.zip.zipcode,
        county: this.zip.county,
        first_name: this.contact.first_name,
        last_name: this.contact.last_name,
        phone: this.contact.phone,
        email: this.contact.email,
        dob: this.demographics.date_of_birth,
        age: moment().diff(moment(this.demographics.date_of_birth, "DD-MM-YYYY"), "years", false),
        gender: this.demographics.gender,
        tobacco: this.demographics.tobacco,
        spouse_set: this.show_spouse == true ? 1 : 0,
        sfirst_name: this.contact.sfirst_name,
        slast_name: this.contact.slast_name,
        sgender: this.demographics.sgender,
        stobacco: this.demographics.stobacco,
        sdob: this.demographics.sdate_of_birth,
        sage: moment().diff(this.demographics.sdate_of_birth, "years"),
      };
      console.log(data);
      axios
        .post(
          "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/add_mm_lead",
          data
        )
        .then((result) => {
          if (result.data.cid) {
            this.main_id = result.data.cid;
          } else {
            this.main_id = result.data.duplicate;
          }

          var nlstates = ["AK", "CT", "DC", "DE", "HI", "ID", "MA", "MD", "ME", "MN", "MT", "ND",
        "NH", "OR", "RI", "SD", "VT", "WI", "WV", "WY", "GU", "PR", "VI"];

          if (nlstates.includes(this.state)) {
            var ehgender = this.demographics.gender == "Male" ? "m" : "f";
            var ehdob = moment(data.dob,'DD-MM-YYYY').format("L");
            console.log(ehdob);
            var url =
              "http://www.ehealthmedicareplans.com/?allid=Med34255&zip=" +
              this.zip.zipcode +
              "&fname=" +
              this.contact.first_name +
              "&lname=" +
              this.contact.last_name +
              "&homephone=" +
              this.contact.phone +
              "&email=" +
              this.contact.email +
              "&bdate1=" +
              ehdob +
              "&gd1=" +
              ehgender +
              "&county=" +
              this.zip.county +
              "&sid=" +
              this.main_id;
            window.location.href = url;
          }
          this.get_medsupp_rates("main");
          if (this.show_spouse == true) {
            this.get_medsupp_rates2("main");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_medsupp_rates(type) {
      var age = moment().diff(moment(this.demographics.date_of_birth, "DD-MM-YYYY"), "years", false);
      var geturl = "";
      geturl =
        "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmalldb/" +
        this.zip.zipcode +
        "/" +
        age +
        "/" +
        this.demographics.gender +
        "/" +
        this.demographics.tobacco +
        "/0/0/" +
        this.demographics.date_of_birth;

      axios.get(geturl).then((result) => {
        var data = result.data.ret;

        var keys = Object.keys(data);

        keys.forEach((element) => {
          var tmp = {
            name: element,
            A: data[element]["A"] == "" ? "" : String(data[element]["A"]),
            F: data[element]["F"] == "" ? "" : String(data[element]["F"]),
            G: data[element]["G"] == "" ? "" : String(data[element]["G"]),
            N: data[element]["N"] == "" ? "" : String(data[element]["N"]),
            NAIC: data[element]["NAIC"],
            img:
              "https://medigap-logos-naic.s3.us-west-2.amazonaws.com/" +
              String(parseInt(data[element]["NAIC"])) +
              ".gif",
            url:
              "https://appenroll.medicaremall.com/?contact_id=" + this.main_id,
          };
          this.medsuprates.push(tmp);
        });

        this.results_loader = false;
        this.show_results = true;
        window.scrollTo(0, 0);
      });
      // if (type == 'main'){
      //     geturl = 'https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmall/' + this.zip.zipcode + "/" + age + "/" + this.demographics.gender + "/" + this.demographics.tobacco + "/0/0/" + this.demographics.date_of_birth
      // }else{
      //     geturl = 'https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmall/' + zipcode + "/" + sage + "/" + sgender + "/" + stobacco + "/" + spouse_set + "/0/" + sdob
      // }
    },

    get_medsupp_rates2(type) {
      var age = moment().diff(moment(this.demographics.sdate_of_birth, "DD-MM-YYYY"), "years", false);
      var geturl = "";
      geturl =
        "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmalldb/" +
        this.zip.zipcode +
        "/" +
        age +
        "/" +
        this.demographics.sgender +
        "/" +
        this.demographics.stobacco +
        "/0/0/" +
        this.demographics.sdate_of_birth;

      axios.get(geturl).then((result) => {
        var data = result.data.ret;

        var keys = Object.keys(data);

        keys.forEach((element) => {
          var tmp = {
            name: element,
            A: data[element]["A"] == "" ? "" : String(data[element]["A"]),
            F: data[element]["F"] == "" ? "" : String(data[element]["F"]),
            G: data[element]["G"] == "" ? "" : String(data[element]["G"]),
            N: data[element]["N"] == "" ? "" : String(data[element]["N"]),
            NAIC: data[element]["NAIC"],
            img:
              "https://www.medicaremall.com/ci/img/medsupp2/" +
              data[element]["NAIC"] +
              ".gif",
            url:
              "https://appenroll.medicaremall.com/?contact_id=" + this.main_id,
          };
          this.medsuprates2.push(tmp);
        });

        this.results_loader2 = false;
        this.show_results2 = true;
        window.scrollTo(0, 0);
      });
      // if (type == 'main'){
      //     geturl = 'https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmall/' + this.zip.zipcode + "/" + age + "/" + this.demographics.gender + "/" + this.demographics.tobacco + "/0/0/" + this.demographics.date_of_birth
      // }else{
      //     geturl = 'https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/csgapi/csgmedmall/' + zipcode + "/" + sage + "/" + sgender + "/" + stobacco + "/" + spouse_set + "/0/" + sdob
      // }
    },
  },
};
</script>
<style scoped>
.btn-primary--alt {
  background-color: #bde774;
  border-color: #174176;
  color: #174176;
  border-width: 0px;
}
.liplus {
  font-weight: 900;
  font-size: 30px;
  color: #2668bd;
}
.icon--na {
  fill: #9a9a9a !important;
}
.icon--check {
  fill: #258c02 !important;
}

.icon--nocheck {
  fill: #c30023 !important;
}
.icon--indicate {
  width: 20px !important;
  height: 20px !important;
}
.icon--small {
  width: 30px !important;
  height: 30px !important;
}
.icon {
  fill: #2668bd;
  width: 50px;
  height: 50px;
}
.msresults:nth-child(even) {
  background: #ccc;
}
.msresults:nth-child(odd) {
  background: #fff;
}

.msrbadge {
  border-radius: 10px;
  background: #174176;
  color: white;
}
</style>
